import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService, AuthState } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { TrackingService } from 'app/services/tracking.service';

declare let pendo;

export const CTV_INSIGHTS_PERMISSION = 'mrtOTTSummary';
export const APP_INSIGHTS_PERMISSION = 'mrtAppSummary';
export const DOMAIN_INSIGHTS_PERMISSION = 'mrtDomainSummary';

type Session = {
  features?: string;
  name?: string;
  scope?: string;
  session?: string;
  user?: string;
  userId?: string;
  userCategory?: string;
  userEmailAddress?: string;
  userName?: string;
};

@Injectable()
export class User {
  public session: Session;
  private _isFreemium = true;
  private _isAnonymous = true;

  constructor(
    private http: HttpClient,
    private tracking: TrackingService,
    private auth: AuthService
  ) {
    this.auth.onAuthStateChange.subscribe(evt => this.onAuthStateChange(evt));
  }

  setCurrentInsightsKey(currentInsightsKey: string) {
    sessionStorage.setItem('PIX_MRT_CURRENT_INSIGHT_KEY', currentInsightsKey);
  }

  getCurrentInsightsKey() {
    return sessionStorage.getItem('PIX_MRT_CURRENT_INSIGHT_KEY');
  }

  onAuthStateChange(state: AuthState) {
    const tokenInfo = this.auth.decodeToken(this.auth.getToken());
    switch (state) {
      case AuthState.ExpiredToken:
      case AuthState.Anonymous:
        this.session = {
          features: 'anonymous,freemium',
          scope: 'none',
          session: '',
          user: '',
          userId: null,
          userCategory: '',
          userEmailAddress: null,
          userName: null
        };

        this.tracking.identifyAnonymous();

        break;
      case AuthState.Authenticated:
        let permissions = tokenInfo['permissions'].join(',');
        // Just need to make sure that there's always a freemium role even if the user doesn't have it
        // since freemium is set in config, freemium is not anymore available in auth0
        if (permissions.indexOf('freemium') < 0) {
          permissions += ',freemium';
        }
        this.session = {
          features: permissions,
          scope: tokenInfo['https://pixalate.com/clientId'] || 'none',
          session: '',
          user: '',
          userId: tokenInfo['https://pixalate.com/userId'],
          userCategory: '',
          userEmailAddress: tokenInfo['https://pixalate.com/email'],
          userName: ''
        };

        this.tracking.identify({
          userId: this.session.userId,
          email: this.session.userEmailAddress,
          clientId: this.session.scope
        });

        break;
    }

    pendo.initialize({
      visitor: {
        id: this.session.userId, // Required if user is logged in
        email: this.session.userEmailAddress // Optional
      },
      account: {
        id: this.session.scope // Highly recommended
      }
    });

    this.updateAnonymousValue();
    this.updateFreemiumValue();
  }

  establishSession() {
    const state = this.auth.getAuthState();

    this.onAuthStateChange(state);
  }

  rankingsEnabled() {
    return (
      this.gstiEnabled() ||
      this.mstiEnabled() ||
      this.vstiEnabled() ||
      this.dqiEnabled() ||
      this.aqiEnabled()
    );
  }

  getProtocol() {
    // let protocol = window.location.protocol;
    //
    // if(protocol === 'http:' || protocol === 'https:') {
    //   return protocol;
    // } else {
    //   return 'http:';
    // }

    return 'https:';
  }

  getEnv() {
    if (window.location.hostname.match('localhost')) {
      return 'dev-';
    } else if (
      window.location.hostname.match('v2.dev.ratings.pixalate.com') ||
      window.location.hostname.match('dev-ratings2.pixalate.com')
    ) {
      return 'dev-';
    }
    return '';
  }

  private getBaseUrl() {
    return `${this.getProtocol()}//${this.getEnv()}idp.pixalate.com/services/2001`;
  }

  private getMetadata() {
    return this.http
      .get(
        `${this.getBaseUrl()}/Metadata/get?uri=${
          this.session.user
        }&callback=JSONP_CALLBACK`
      )
      .pipe(map(res => res));
  }

  getSessionInfo() {
    const options = {
      withCredentials: true
    };
    return this.http.get(
      `${this.getBaseUrl()}/Realm/getSessionInfo?nocache=${Math.random()}`,
      options
    );
  }

  public getSessionScope() {
    return this.session.scope;
  }

  public getSessionId() {
    return this.session ? this.session.session : '';
  }

  public getSessionUserCategory() {
    return this.session.userCategory;
  }

  public getIdentity() {
    if (this.session) {
      return {
        userId: this.session.session,
        userEmailAddress: this.session.userEmailAddress,
        userCategory: this.session.userCategory,
        userName: this.session.userName,
        name: this.session.name,
        clientId: this.session.scope
      };
    } else {
      return null;
    }
  }

  public idpLogout() {
    return this.http.get(
      `${this.getBaseUrl()}/Realm/logout?callback=JSONP_CALLBACK`
    );
  }

  public logout() {
    return new Promise(async resolve => {
      this.session = {};
      const res = await this.idpLogout().toPromise();
      this.setCurrentInsightsKey(null);
      resolve(res);
    });
  }

  public accountAdminEnabled(): boolean {
    return this.hasPermission(['accountAdmin']);
  }

  public updateAnonymousValue() {
    if (
      !(this.session && this.session.features) ||
      this.session.features.indexOf('anonymous') > -1
    ) {
      this._isAnonymous = true;
    } else {
      this._isAnonymous = false;
    }
  }

  public updateFreemiumValue() {
    if (this.session && this.session.features) {
      if (
        this.session.features.replace('mrtAppTrends', '').indexOf('mrt') ===
          -1 &&
        (this.session.features.indexOf('freemium') !== -1 ||
          this.session.features.indexOf('anonymous') !== -1)
      ) {
        this._isFreemium = true;
        return;
      }
    }
    this._isFreemium = this.isAnonymous();
  }

  public isAnonymous(): boolean {
    return this._isAnonymous;
  }

  public isFreemium(): boolean {
    return this._isFreemium;
  }

  public gstiEnabled(): boolean {
    return this.hasPermission(['mrtSellerTrustIndex']);
  }

  public adsTxtEnabled(): boolean {
    return this.hasPermission(['mrtAdsDotTxt']);
  }

  public mstiEnabled(): boolean {
    return this.hasPermission(['mrtMobileSellerTrustIndex']);
  }

  public vstiEnabled(): boolean {
    return this.hasPermission(['mrtVideoSellerTrustIndex']);
  }

  public aqiEnabled(): boolean {
    return this.hasPermission(['mrtMobileAppRanking']);
  }

  public appInsightsCountryEnabled(): boolean {
    return this.hasPermission(['mrtAppInsightsCountry']);
  }

  public ctvInsightsCountryEnabled(): boolean {
    return this.hasPermission(['mrtCtvInsightsCountry']);
  }

  public ottAppSpoofingEnabled(): boolean {
    return this.hasPermission(['mrtOTTRatingsWithSpoofing']);
  }

  public appAdSpendEnabled(): boolean {
    return this.hasPermission(['mrtAppAdSpend']);
  }

  public appRankingsEnabled(): boolean {
    return this.hasPermission(['mrtAppInsightsRankings']);
  }

  public appCoppaPermissionsRiskEnabled(): boolean {
    return this.hasPermission(['mrtAppInsightsCoppaPermissionRisk']);
  }

  public ctvCoppaPermissionsRiskEnabled(): boolean {
    return this.hasPermission(['mrtCtvInsightsCoppaPermissionRisk']);
  }

  public ctvAdSpendEnabled(): boolean {
    return this.hasPermission(['mrtOTTAdSpend']);
  }

  public dqiEnabled(): boolean {
    return this.hasPermission(['mrtDomainQualityIndex']);
  }

  public domainEnabled(): boolean {
    return this.hasPermission([DOMAIN_INSIGHTS_PERMISSION]);
  }

  public appEnabled(): boolean {
    return this.hasPermission([APP_INSIGHTS_PERMISSION]);
  }

  public ctvEnabled(): boolean {
    return this.hasPermission([CTV_INSIGHTS_PERMISSION]);
  }

  public ottDiscoveryEnabled(): boolean {
    return this.hasPermission(['mrtOTTDiscovery']);
  }

  public ottDownloadCsvEnabled(): boolean {
    return this.hasPermission(['mrtOTTDownloadCSV']);
  }

  public domainSearchEnabled(): boolean {
    return this.hasPermission(['mrtSearch']);
  }

  public appDiscoveryEnabled(): boolean {
    return this.hasPermission(['mrtAppDiscovery']);
  }

  public appTrendsEnabled(): boolean {
    return this.hasPermission(['mrtAppTrends']);
  }

  public plannerEnabled(): boolean {
    return this.hasPermission(['mrtMediaPlanner']);
  }

  public domainBulkUploadEnabled(): boolean {
    return this.hasPermissions(['mrtApiSummary', 'mrtDomainImport']);
  }

  public appBulkUploadEnabled(): boolean {
    return this.hasPermissions(['mrtApiSummary', 'mrtAppImport']);
  }

  public ctvBulkUploadEnabled(): boolean {
    return this.hasPermissions(['mrtOTTImport']);
  }

  /**
   * Returns true if passed permission is a single string and the user has it
   * Returns true if passed permission is an array of string and the user has it all
   *
   * @param permission a permission or list of permissions
   * @returns boolean
   */
  public hasPermission(permission: string | string[]): boolean {
    if (this.session?.features && permission) {
      if (Array.isArray(permission)) {
        for (let i = 0; i < permission.length; i++) {
          if (this.session.features.indexOf(permission[i]) === -1) {
            return false;
          }
        }
        return true;
      } else {
        if (this.session.features.indexOf(permission) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Returns true if user has atleast one of the permissions
   *
   * @param permissions array of permissions
   * @returns boolean
   */
  public hasPermissions(permissions: Array<string>): boolean {
    if (this.session?.features && permissions) {
      for (let i = 0; i < permissions.length; i++) {
        if (this.session.features.indexOf(permissions[i]) !== -1) {
          return true;
        }
      }
    }
    return false;
  }
}
