import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api-service';
import { User } from '../models/User';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApigeeService extends BaseApiService {
  constructor(private http: HttpClient, private user: User) {
    super();
  }

  getNumWatchingById(id: string, type: string) {
    return new Promise<any>((resolve, reject) => {
      const url = `${this.getApiGatewayBaseUrl()}/followers/${type}/${id}?perPage=0`;
      this.http.get(url).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getNumViewingById(id: string, type: string) {
    return new Promise<any>((resolve, reject) => {
      const url = `${this.getApiGatewayBaseUrl()}/visitors/${type}/${id}?numDays=120&pretty=true`;
      this.http.get(url).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  addToWatchlistById(appId: string, email: string, type: string) {
    return new Promise<any>((resolve, reject) => {
      const url = `${this.getApiGatewayBaseUrl()}/followers/${type}/${appId}/${email}/`;
      this.http.put(url, null).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  unfollowById(id: string, email: string, type: string) {
    return new Promise<any>((resolve, reject) => {
      const url = `${this.getApiGatewayBaseUrl()}/followers/${type}/${id}/${email}/`;
      this.http.delete(url).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  isFollowing(id: string, email: string, type: string) {
    return new Promise<any>((resolve, reject) => {
      const url = `${this.getMrtBaseUrl()}/followers/${type}/${id}/${email}?pretty=true`;
      this.http.get(url).subscribe(
        res => {
          if (res && res['followId'] && res['followId'] === id) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }

  async getFollowedByUser(
    email: string,
    type: string,
    countOnly: boolean,
    recursive: boolean = false
  ) {
    if (countOnly === true && recursive === true)
      throw new Error('countOnly and recursive cannot both be true');

    return new Promise<any>(async (resolve, reject) => {
      let page = 0;
      let numFound = 0;
      let perPage = 20;
      if (countOnly) perPage = 0;

      const baseUrl = `${this.getApiGatewayBaseUrl()}/users/${email}/follows/`;

      let docs: Array<any> = new Array<any>();

      do {
        let url = baseUrl;
        if (type) url = `${baseUrl}${type}`;

        url = `${url}?page=${page}&perPage=${perPage}`;

        try {
          const data: any = await new Promise<any>((rev, rej) => {
            this.http.get(url).subscribe(
              res => {
                rev(res);
              },
              err => {
                console.log('ERROR: ', err);
                rej(err);
              }
            );
          });

          page += 1;
          numFound = data.numFound;
          docs = docs.concat(data.docs);
        } catch (err) {
          reject(err);
        }
      } while (recursive && page * perPage < numFound);

      resolve({ docs, numFound });
    });
  }
}
