/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api-service';
import {
  NotificationSettings,
  NotificationSettingsDTO
} from 'app/models/notification-settings';
import { MrtType } from 'app/types/mrt-type';
import { MrtOs } from 'app/types/mrt-os';
import { User } from 'app/models/User';
import {
  mapArrayDTOSettings,
  mapArraySettingsDTO
} from 'app/functions/map-array-setting';
import { CHANNELS, METRICS } from 'app/constants/notification-settings';
import { getAppGroup } from 'app/functions/get-app-group';

export const LOCALSTORAGE_KEY = 'pix-notification-settings';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseApiService {
  constructor(private http: HttpClient, private _userService: User) {
    super();
  }

  updateGlobalSettings(data: NotificationSettings): void {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(data));
  }

  getGlobalSettings(): NotificationSettings {
    let globalSettings: NotificationSettings = {
      metrics: {
        overallRiskChange: true,
        ivtChange: true,
        viewabilityChange: true,
        appAdsTxt: true,
        blocklisted: true,
        coppaViolationRisk: true,
        appPermissionRisk: true,
        brandSafety: true,
        givt: true,
        sivt: true,
        dau: true,
        mau: true,
        apSpend: true,
        spoofingRisk: true,
        privacyPolicy: true,
        tac: true,
        location: true,
        impressionVolume: true,
        authorizedSeller: true
      },
      channels: {
        email: false,
        slack: false,
        inApp: true
      },
      frequency: 'weekly'
    };

    const storedGlobalSettings = localStorage.getItem(LOCALSTORAGE_KEY);
    if (storedGlobalSettings) {
      globalSettings = Object.assign(
        globalSettings,
        JSON.parse(storedGlobalSettings)
      );
    }
    return globalSettings;
  }

  adjustNotificationSetting(
    id: string,
    os: string,
    settings: NotificationSettings | null
  ) {
    return new Promise<any>((resolve, reject) => {
      if (!id || !os) {
        return reject(new Error('Missing required params.'));
      }

      const userInfo = this._userService.getIdentity();
      if (!userInfo) {
        return reject(new Error('No user found.'));
      }

      if (!settings) settings = this.getGlobalSettings();

      const metrics = mapArraySettingsDTO(METRICS, settings.metrics);
      const channels = mapArraySettingsDTO(CHANNELS, settings.channels);
      const dto: NotificationSettingsDTO = {
        userName: userInfo.userEmailAddress,
        clientId: userInfo.clientId,
        id,
        mrtType: getAppGroup(os) as MrtType,
        os: os as MrtOs,
        notification: {
          metrics,
          channels,
          frequency: settings.frequency
        }
      };

      const url = `${this.getAlertBaseUrl()}/adjustSubscription`;
      return this.http.post<any>(url, dto).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  deleteNotificationSetting(id: string, os: string) {
    return new Promise<any>((resolve, reject) => {
      if (!id || !os) {
        return reject(new Error('Missing required params.'));
      }

      const userInfo = this._userService.getIdentity();
      if (!userInfo) {
        return reject(new Error('No user found.'));
      }

      const dto: NotificationSettingsDTO = {
        userName: userInfo.userEmailAddress,
        clientId: userInfo.clientId,
        id,
        mrtType: getAppGroup(os) as MrtType,
        os: os as MrtOs
      };
      const url = `${this.getAlertBaseUrl()}/deleteSubscription`;
      return this.http.post<any>(url, dto).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getAllNotificationSettings() {
    return new Promise<any>((resolve, reject) => {
      const userInfo = this._userService.getIdentity();
      if (!userInfo) {
        return reject(new Error('No user found.'));
      }
      const userName = userInfo.userEmailAddress;
      const clientId = userInfo.clientId;
      const url = `${this.getAlertBaseUrl()}/getAllSubscriptions?clientId=${clientId}&userName=${encodeURIComponent(
        userName
      )}`;
      return this.http.get<any>(url).subscribe(
        res => {
          resolve(res.docs);
        },
        err => {
          console.error(
            'Failed to retrieve list of notification settings.',
            err
          );
          reject(err);
        }
      );
    });
  }

  getNotificationSetting(id: string, os: string) {
    return new Promise<any>((resolve, reject) => {
      const userInfo = this._userService.getIdentity();
      if (!userInfo) {
        return reject(new Error('No user found.'));
      }
      const dto: NotificationSettingsDTO = {
        userName: userInfo.userEmailAddress,
        clientId: userInfo.clientId,
        id,
        mrtType: getAppGroup(os) as MrtType,
        os: os as MrtOs
      };
      const url = `${this.getAlertBaseUrl()}/getSubscription`;
      return this.http.post<any>(url, dto).subscribe(
        res => {
          const settings: NotificationSettings = {
            appId: id,
            appGroup: dto.mrtType,
            appStore: os,
            metrics: mapArrayDTOSettings(METRICS, res.metrics),
            channels: mapArrayDTOSettings(CHANNELS, res.channels),
            frequency: res.frequency || 'daily'
          };
          resolve(settings);
        },
        err => {
          reject(err);
        }
      );
    });
  }
}
