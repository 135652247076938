import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from './base-api-service';
import { UserInfo } from '../models/UserInfo';

const HEARTBEAT_INTERVAL = 1000;

export enum AuthState {
  Anonymous,
  Authenticated,
  ExpiredToken
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {
  token: string;
  onAuthStateChange: EventEmitter<AuthState> = new EventEmitter<AuthState>();

  constructor(private http: HttpClient) {
    super();

    this.setupHeartbeat();
  }

  setupHeartbeat() {
    let state = this.getAuthState();
    let tokenInfo = this.decodeToken(this.getToken());
    let userId = tokenInfo && tokenInfo['https://pixalate.com/userId'];

    setInterval(() => {
      const newState = this.getAuthState();

      if (
        newState === AuthState.Authenticated &&
        state === AuthState.Authenticated
      ) {
        tokenInfo = this.decodeToken(this.getToken());

        const sub = tokenInfo['https://pixalate.com/userId'];

        if (userId !== sub) {
          this.onAuthStateChange.emit(newState);
          state = newState;
          userId = sub;
        }
      } else if (newState !== state) {
        if (newState === AuthState.ExpiredToken) {
          this.logout();
          return;
        } else {
          this.onAuthStateChange.emit(newState);
          state = newState;
        }
      }
    }, HEARTBEAT_INTERVAL);
  }

  login() {
    this.clearToken(); // always clear token to prevent infinite redirection loop
    window.location.href = `https://${this.getAccountHost()}/login?continue=${encodeURIComponent(
      window.location.href
    )}`;
  }

  logout() {
    this.clearToken(); // always clear token to prevent infinite redirection loop
    window.location.href = `https://${this.getAccountHost()}/logout?continue=${encodeURIComponent(
      window.location.href
    )}`;
  }

  signup() {
    window.location.href = `https://${this.getAccountHost()}/signup?continue=${encodeURIComponent(
      window.location.href
    )}`;
  }

  signupWithFollow() {
    const loc = window.location;
    const action = (loc.href.indexOf('?') > -1 ? '&' : '?') + 'action=follow';
    const redirect = loc.href.replace(loc.hash, '') + action + loc.hash;
    window.location.href = `https://${this.getAccountHost()}/signup?continue=${encodeURIComponent(
      redirect
    )}`;
  }

  getUserInfo(): Promise<UserInfo> {
    return new Promise<UserInfo>((resolve, reject) => {
      const tokenInfo = this.decodeToken(this.getToken());
      const userId = tokenInfo['https://pixalate.com/userId'];
      const url = `${this.getApiGatewayBaseUrl('v3')}/users/${userId}`;
      this.http.get(url).subscribe(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updatePassword(userId: string, token: string, password: string) {
    return new Promise((resolve, reject) => {
      const url = `${this.getApigeeBaseUrl()}/users/${userId}`;
      this.http.patch(url, { password: password }).subscribe(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  decodeToken(token: string) {
    if (token && token.length > 0 && token !== 'null') {
      const base64Url = token.split('.')[1];
      if (base64Url && base64Url.length > 0) {
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(
          decodeURIComponent(
            atob(base64)
              .split('')
              .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join('')
          )
        );
      }
    }
    return null;
  }

  isAuthenticated() {
    const token = this.getToken();
    const decodedToken = this.decodeToken(token);

    if (!decodedToken) {
      return false;
    }

    if (decodedToken.exp * 1000 < Date.now()) {
      return false;
    }

    const hasToken = token && token !== null && token !== 'null';
    return hasToken;
  }

  getAuthState(): AuthState {
    const token = this.getToken();
    const decodedToken = this.decodeToken(token);

    if (!decodedToken) {
      return AuthState.Anonymous;
    }

    if (decodedToken.exp * 1000 < Date.now()) {
      return AuthState.ExpiredToken;
    }

    const hasToken =
      token && token !== null && token !== 'null' && token.length > 0;

    if (hasToken) {
      return AuthState.Authenticated;
    }

    return AuthState.Anonymous;
  }

  private getCookie(cname: string) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) !== -1) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  getToken() {
    return this.getCookie(this.getAuthCookie());
  }

  clearToken() {
    const hostname = location.hostname;
    const domain =
      hostname.indexOf('localhost') >= 0 ? 'localhost' : '.pixalate.com';
    document.cookie = `${this.getAuthCookie()}=;domain=${domain}; Max-Age=0`;
  }
}
