import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  TemplateRef,
  Output,
  EventEmitter
} from '@angular/core';
import { User } from 'app/models/User';
import { AlgoliaService } from 'app/services/algolia.service';
import {
  Breakpoint,
  MediaQueryService
} from 'app/services/media-query.service';
import { retryWithBackoff } from 'app/utils';
import { Hits } from 'instantsearch.js/es/types';
import { EMPTY, Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { SEARCH_CONTEXT } from '../ALGOLIA_CONFIG';
import { StripNlnlPipe } from '../strip-nlnl.pipe';
import { EventBusService } from 'app/services/EventBusService';

export interface QueryParams {
  query?: string;
  filters?: string;
  facetFilters?: string;
  numericFilters?: string;
}

export interface InfiniteState {
  results: {
    params?: string;
    index?: string;
  };
}

@Component({
  selector: 'app-kcs-results',
  templateUrl: './kcs-results.component.html',
  styleUrls: ['./kcs-results.component.scss']
})
export class KcsResultsComponent {
  Breakpoint = Breakpoint;

  @Input() resultTemplate: TemplateRef<{
    hits: Hits;
    viewType: 'tile' | 'list';
  }>;

  @Input()
  key = 'content';

  @Input()
  searchContext: SEARCH_CONTEXT;

  @Input()
  restrictSearchableAttributes: string[];

  @Output() mobileFilterMenuClick: EventEmitter<MouseEvent> =
    new EventEmitter();

  @ViewChild('infinite') infinite: ElementRef;

  viewType = 'tile';
  downloading = false;

  mobile$: Observable<boolean>;

  constructor(
    private user: User,
    private algolia: AlgoliaService,
    private http: HttpClient,
    private mq: MediaQueryService,
    private _eventBus: EventBusService
  ) {
    this.mobile$ = this.mq.lte(Breakpoint.md);
  }

  transformHits(hits) {
    const stripNlnlPipe: StripNlnlPipe = new StripNlnlPipe();
    hits.forEach(hit => {
      hit.stars = [];
      for (let i = 1; i <= 5; i++) {
        hit.stars.push(i <= hit.avgUserRating);
      }
      const matchList = [];
      const lookup = ['description', 'title', 'publisherName', 'pp_corpus'];
      const highlightResult = hit._highlightResult;
      if (highlightResult) {
        lookup.forEach(text => {
          if (highlightResult[text] && highlightResult[text].matchedWords) {
            highlightResult[text].matchedWords.forEach(word => {
              if (matchList.indexOf(word) < 0) {
                matchList.push(word);
              }
            });
          }
        });
      }

      hit.matched = matchList.length;
      if (
        hit?._highlightResult?.description &&
        (hit._highlightResult.description.value.indexOf('NLNL') >= 0 ||
          hit._highlightResult.description.value.indexOf('CRCR') >= 0)
      ) {
        hit._highlightResult.description.value = stripNlnlPipe.transform(
          hit._highlightResult.description.value
        );
      }

      let { adDomain, appStore, appId, region, device } = hit;

      if (appStore === 'ios' || appStore === 'android') {
        hit.routerLink = ['/insight/apps/', appId, region, appStore, device];
      } else if (
        appStore === 'roku' ||
        appStore === 'firetv' ||
        appStore === 'samsung' ||
        appStore === 'tvos'
      ) {
        hit.routerLink = ['/insight/ctv/', appId, region, device];
      } else {
        hit.routerLink = ['/insight/domains', adDomain, region, device];
      }
    });
    return hits;
  }

  viewTypeClick(viewType: string) {
    this.viewType = viewType;
  }

  download(format: string) {
    if (this.user.isFreemium()) {
      window.location.href = 'https://www.pixalate.com/scheduledemo';
    } else {
      const state: InfiniteState = this.infinite['state'];
      const queryParams: QueryParams = this.extractQueryParams(
        state.results.params
      );
      const reportIdParam =
        `reportId=` +
        (this.searchContext == SEARCH_CONTEXT.PRIVACY
          ? 'algoliaPrivacyPolicy'
          : 'algoliaSearch');
      const indexParam = `indexName=${state.results.index}`;

      const filtersParam = `filters=${queryParams.filters}`;
      const facetFiltersParam = `facetFilters=${
        queryParams.facetFilters || ''
      }`;
      const numericFiltersParam = `numericFilters=${(
        queryParams.numericFilters || ''
      )
        .split('%2C')
        .map(f => {
          return `%5B${f}%5D`;
        })
        .join(',')}`;

      const queryParam = `query=${queryParams.query}`;

      let restrictSearchableAttributesParam = '';
      if (this.searchContext == SEARCH_CONTEXT.SIMPLE_SEARCH) {
        restrictSearchableAttributesParam =
          `restrictSearchableAttributes=%5B`.concat(
            this.restrictSearchableAttributes.join('%2C').concat('%5D')
          );
      }

      let optionalWords = '';
      // don't apply optional words on simple search
      if (
        this.searchContext != SEARCH_CONTEXT.SIMPLE_SEARCH &&
        !queryParam.match(/["']/g)
      ) {
        let optionalWordsStr = '';
        optionalWordsStr = queryParams.query
          .split('%20')
          .map(f => {
            return `%22${f}%22`;
          })
          .join('%2C');
        optionalWords = `optionalWords=%5B${optionalWordsStr}%5D`;
      }

      const formatParam = format == 'json' ? '' : 'mimeType=text/csv';

      this.downloading = true;

      void this.algolia
        .download(
          `${reportIdParam}&${indexParam}&${queryParam}&${filtersParam}&${facetFiltersParam}&${numericFiltersParam}&${formatParam}&${optionalWords}&${restrictSearchableAttributesParam}`
        )
        .then((exportUrl: string) => {
          exportUrl = exportUrl.replace(/"/g, '');

          const backoff = 2000;
          const maxRetries = 10;
          const baseDelay = 1000;

          this.http
            .head(exportUrl, {
              observe: 'response'
            })
            .pipe(
              timeout(5000),
              retryWithBackoff(baseDelay, maxRetries, backoff),
              catchError(err => {
                throw err;
              })
            )
            .subscribe(response => {
              this.downloading = false;
              if (response.url.indexOf('reportHttpError') > -1) {
                const params = this.extractQueryParams(
                  response.url.split('?')[1]
                );
                if (params['errorCode'] == 403) {
                  this._eventBus.showQuotaError('Insufficient quota.', false);
                }
              } else {
                window.location.href = exportUrl;
              }
            });
        })
        .catch(err => {
          console.error('ERR RESPONSE', err);
          this.downloading = false;
          return EMPTY;
        });
    }
  }

  extractQueryParams(params: string): QueryParams {
    const urlParts = params.split(/[?&=]/);
    const queryParams = {};
    for (let i = 0; i < urlParts.length; i = i + 2) {
      queryParams[urlParts[i]] = urlParts[i + 1];
    }
    return queryParams;
  }

  decodeParams(param: string, oldVals: string[], newVals: string[]) {
    if (!param || !param.length) return '';

    for (let i = 0; i < oldVals.length; i++) {
      const regex = new RegExp(oldVals[i], 'gi');
      const found = param.match(regex);
      found &&
        found.forEach(key => {
          param = param.replace(key, newVals[i]);
        });
    }
    return param;
  }
}
