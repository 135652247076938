import { Injectable } from '@angular/core';
import { Angulartics2Segment } from 'angulartics2/segment';
import { BaseApiService } from './base-api-service';

export interface UserTrackingProperties {
  userId?: string;
  clientId?: string;
  email?: string;
}

@Injectable()
export class TrackingService extends BaseApiService {
  private userProperties: UserTrackingProperties;
  private disabled: boolean = false;

  constructor(private segment: Angulartics2Segment) {
    super();
    this.disabled = this.getEnv() !== 'prod'; // disabled if not production
  }

  public identify(properties: UserTrackingProperties) {
    if (this.disabled) return;

    this.segment.setUserProperties(properties);
    this.userProperties = properties;
  }

  public identifyAnonymous() {
    if (this.disabled) return;

    this.segment.unsetUserProperties();
    this.userProperties = {};
  }

  public eventTrack(action: string, properties: any): void {
    if (this.disabled) return;

    const { clientId, email } = this.userProperties;
    if (clientId) {
      properties['clientid'] = clientId;
    }
    if (email) {
      properties['email'] = properties['email'] || email;
    }

    this.segment.eventTrack(action, properties);
  }
}
