/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';

export const ENV_VARS = {
  dev: {
    accountHost: 'dev-account.pixalate.com',
    authCookie: 'pix-tok-dev',
    apiHost: 'dev-ratings-srvc.pixalate.com',
    alertHost: 'dev-ratings-srvc.pixalate.com',
    apigeeHost: 'dev-api.pixalate.com',
    apiGatewayHost: 'dev-api.pixalate.com',
    xApiKey: '4d1e6f120fcca867440d71ca51adbf75'
  },
  stg: {
    accountHost: 'account.pixalate.com',
    authCookie: 'pix-tok',
    apiHost: 'stg-ratings-srvc.pixalate.com',
    alertHost: 'stg-ratings-srvc.pixalate.com',
    apigeeHost: 'api.pixalate.com',
    apiGatewayHost: 'api.pixalate.com',
    xApiKey: '71bb7531281982379c8845a929330071'
  },
  prod: {
    accountHost: 'account.pixalate.com',
    authCookie: 'pix-tok',
    apiHost: 'ratings-srvc.pixalate.com',
    alertHost: 'ratings-srvc.pixalate.com',
    apigeeHost: 'api.pixalate.com',
    apiGatewayHost: 'api.pixalate.com',
    xApiKey: '191090b5166010b110faca5bb427d703'
  }
};

export const HOSTS_ENV = {
  localhost: 'dev',
  'ngrok.io': 'dev',
  'dev-ratings.pixalate.com': 'dev',
  'dev-ft1-ratings.pixalate.com': 'dev',
  'dev-ft2-ratings.pixalate.com': 'dev',
  'dev-ft3-ratings.pixalate.com': 'dev',
  'stg-ratings.pixalate.com': 'stg',
  'ratings.pixalate.com': 'prod'
};

export const FT_ENV = {
  localhost: 'ft1',
  'dev-ft1-ratings.pixalate.com': 'ft1',
  'dev-ft2-ratings.pixalate.com': 'ft2',
  'dev-ft3-ratings.pixalate.com': 'ft3'
};

@Injectable({
  providedIn: 'root'
})
export class EnvConfigService {
  constructor() {}

  getEnv() {
    const hostname = window.location.hostname;
    return HOSTS_ENV[hostname];
  }

  getFtEnv() {
    const hostname = window.location.hostname;
    return FT_ENV[hostname] ? `-${FT_ENV[hostname]}` : '';
  }

  getAccountHost() {
    return ENV_VARS[this.getEnv()].accountHost;
  }

  getAuthCookie() {
    return ENV_VARS[this.getEnv()].authCookie;
  }

  getApiHost() {
    return ENV_VARS[this.getEnv()].apiHost;
  }

  getAlertHost() {
    return ENV_VARS[this.getEnv()].alertHost;
  }

  getApigeeHost() {
    return ENV_VARS[this.getEnv()].apigeeHost;
  }

  getApiGatewayHost() {
    return ENV_VARS[this.getEnv()].apiGatewayHost;
  }

  getXApiKey() {
    return ENV_VARS[this.getEnv()].xApiKey;
  }

  getProtocol() {
    return window.location.protocol;
  }
  getPageHost() {
    return window.location.host;
  }
}
