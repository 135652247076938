import { NgModule } from '@angular/core';
import { TooltipDirective } from './tooltip.directive';
import { ProgressCircleChartDirective } from './progress-circle-chart/progress-circle-chart.directive';
import { ProgressCircleDirective } from './progress-circle-chart/progress-circle.directive';
import { OnvisibleDirective } from './onvisible.directive';
import { PopoverDirective } from './popover.directive';
import { CarouselSwipeDirective } from './carousel-swipe.directive';
import { PerfectScrollbarDirective } from './perfect-scrollbar.directive';
import { AnonymousLockDirective } from './anonymous-lock.directive';

@NgModule({
  imports: [],
  declarations: [
    TooltipDirective,
    ProgressCircleDirective,
    ProgressCircleChartDirective,
    OnvisibleDirective,
    PopoverDirective,
    CarouselSwipeDirective,
    PerfectScrollbarDirective,
    AnonymousLockDirective
  ],
  exports: [
    TooltipDirective,
    ProgressCircleDirective,
    ProgressCircleChartDirective,
    OnvisibleDirective,
    PopoverDirective,
    CarouselSwipeDirective,
    PerfectScrollbarDirective,
    AnonymousLockDirective
  ]
})
export class DirectivesModule {}
