/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { EnvConfigService } from './env-config.service';

@Injectable()
export class BaseApiService extends EnvConfigService {
  getMrtBaseUrl() {
    return `https://${this.getApiHost()}`;
  }
  getReportBaseUrl(yearPrefix = '2018') {
    return `https://${this.getApiHost()}/services/${yearPrefix}/Report`;
  }
  getRatingsBaseUrl(yearPrefix = '2018') {
    return `https://${this.getApiHost()}/services/${yearPrefix}/Ratings`;
  }
  getAlertBaseUrl(yearPrefix = '2020') {
    return `https://${this.getAlertHost()}/services/${yearPrefix}/P0`;
  }
  getApigeeBaseUrl(version = 'v3') {
    return `https://${this.getApigeeHost()}/api/${version}`;
  }
  getApiGatewayBaseUrl(version = 'v2') {
    return `https://${this.getApiGatewayHost()}/api/${version}`;
  }
  storeCache(res: any, dataCache: any, urlString: any) {
    if (res['status'] && res['status'] === 401) {
      // Do Nothing
    } else {
      dataCache[urlString] = JSON.parse(JSON.stringify(res));
    }
  }
}
