/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api-service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';

declare let jQuery;

@Injectable()
export class CtvService extends BaseApiService {
  appDetailsCachedObservables: any = {};
  appChangeLogCacheData: any = {};
  availableCountriesCache: any = {};
  availableMonthsCache: any = {};
  facetsDataCache: any = {};
  discoveryDataCache: any = {};
  appIconCoordinates: any;
  region: string;
  country: string;
  device: string;
  month: string;
  includeSpoofing: boolean;
  isFollower: boolean;
  reportDataCache: any = {};

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
  }

  setFollowing(isFollower: boolean) {
    this.isFollower = isFollower;
  }

  storeAppIconCoordinates(coordinates: any) {
    this.appIconCoordinates = coordinates;
  }

  setRegion(region: string) {
    this.region = region;
  }

  setCountry(country: string) {
    this.country = country;
  }

  setDevice(device: string) {
    this.device = device;
  }

  setMonth(month: string) {
    this.month = month;
  }

  setIncludeSpoofing(includeSpoofing: boolean) {
    this.includeSpoofing = includeSpoofing;
  }

  getAllData(
    appid: string,
    regionParam?: string,
    deviceParam?: string,
    countryParam?: string,
    monthParam?: string,
    includeSpoofingParam?: boolean
  ) {
    const device = deviceParam ? deviceParam : this.device;
    const region = regionParam ? regionParam : this.region;
    const country = countryParam ? countryParam : this.country;
    const month = monthParam ? monthParam : this.month;
    const includeSpoofing =
      includeSpoofingParam != undefined
        ? includeSpoofingParam
        : this.includeSpoofing;
    let query = `WHERE appId = '${appid}' AND device = '${device}' AND region = '${region}'`;
    if (month && month.length > 0) {
      query += ` AND month = '${month}'`;
    }
    if (country && country.length > 0) {
      query += ` AND country = '${country}'`;
    }

    const urlString =
      `${this.getReportBaseUrl('2018')}/getWidget?q=${query}&reportId=ctvMrt&` +
      `widgetId=ctvAppSummary&includeSpoofing=${includeSpoofing}`;

    return this.getCachedObservable(urlString);
  }

  getAvailableFilters(
    appid: string,
    regionParam?: string,
    deviceParam?: string,
    countryParam?: string,
    includeSpoofingParam?: boolean
  ) {
    const device = deviceParam ? deviceParam : this.device;
    const region = regionParam ? regionParam : this.region;
    const country = countryParam ? countryParam : this.country;
    const includeSpoofing =
      includeSpoofingParam != undefined
        ? includeSpoofingParam
        : this.includeSpoofing;

    let query = `WHERE appId = '${appid}' AND device = '${device}' AND region = '${region}'`;
    if (country && country.length > 0) {
      query += ` AND country = '${country}'`;
    }

    const urlString =
      `${this.getReportBaseUrl('2018')}/getWidget?reportId=ctvMrt&` +
      `widgetId=availableFilters&includeSpoofing=${includeSpoofing}&q=${query}`;

    return this.getCachedObservable(urlString);
  }

  getCachedObservable(urlString: string) {
    const cacheKey = `${this.auth.getAuthState()}:${urlString}`;
    let cachedObservable = this.appDetailsCachedObservables[cacheKey];

    if (!cachedObservable) {
      cachedObservable = this.http.get(urlString).pipe(shareReplay());
      this.appDetailsCachedObservables[cacheKey] = cachedObservable;
    }

    return cachedObservable.toPromise();
  }

  getAppScopeData(
    appid: string,
    regionParam?: string,
    deviceParam?: string,
    countryParam?: string,
    monthParam?: string,
    includeSpoofingParam?: boolean
  ) {
    const device = deviceParam ? deviceParam : this.device;
    const region = regionParam ? regionParam : this.region;
    const country = countryParam ? countryParam : this.country;
    const month = monthParam ? monthParam : this.month;
    const includeSpoofing =
      includeSpoofingParam != undefined
        ? includeSpoofingParam
        : this.includeSpoofing;
    return new Promise(resolve => {
      let query = `WHERE appId = '${appid}' AND device = '${device}' AND region = '${region}'`;
      if (month && month.length > 0) {
        query += ` AND month = '${month}'`;
      }
      if (country && country.length > 0) {
        query += ` AND country = '${country}'`;
      }

      const urlString =
        `${this.getReportBaseUrl(
          '2018'
        )}/getWidget?q=${query}&reportId=ctvMrt&` +
        `widgetId=availableCountries&includeSpoofing=${includeSpoofing}`;

      const cacheKey = `${this.auth.getAuthState()}:${urlString}`;

      if (this.availableCountriesCache[cacheKey]) {
        const cacheData = this.availableCountriesCache[cacheKey];
        resolve(JSON.parse(JSON.stringify(cacheData)));
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.availableCountriesCache, cacheKey);
          resolve(res);
        });
      }
    });
  }

  getAvailableMonths(reportId = 'ctvMrt'): Promise<any> {
    if (!this.availableMonthsCache[reportId]) {
      this.availableMonthsCache[reportId] = new Promise((resolve, reject) => {
        const urlString = `${this.getRatingsBaseUrl()}/getWidget?reportId=${reportId}&widgetId=availableMonths`;
        this.http.get(urlString).subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      });
    }
    return this.availableMonthsCache[reportId];
  }

  getFacets(filterString) {
    return new Promise(resolve => {
      let urlString =
        `${this.getRatingsBaseUrl('2018')}/getCtvFacets?` +
        `facet=pixalateRisk&facet=appAgeRisk&facet=spoofingRisk&facet=brandSafetyRisk&` +
        `facet=advisoriesRisk&facet=adultContentRisk&facet=alcoholContentRisk&facet=drugContentRisk&` +
        `facet=hateSpeechRisk&facet=offensiveContentRisk&facet=violenceContentRisk&facet=isAdsDetected&` +
        `facet=isAdsTxtEnabled&facet=isPrivateDomain&facet=hasTermsAndConditionLink&facet=hasPrivacyLink&` +
        `facet=isCvaaCompliant&facet=brandSafetyContentRisk&facet=imagesAdultContentRisk&` +
        `facet=isChannelAggregator&facet=hasAppPrivacyPolicyLink&facet=isExistInAppStore&` +
        `facet=imagesViolenceContentRisk&facet=coppaRisk&facet=coppaTargetChildrenRisk&facet=mfaRating&` +
        `facet=ownerDomain`;
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.facetsDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  getFacetsBySearch(
    selectedDevice: string,
    selectedRegion: string,
    param: Array<string>,
    paramSearch: string,
    paramSearchValue: string,
    filterString?: string
  ) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/getCtvFacets?nocache=${Math.random()}`;
      if (selectedRegion) {
        urlString = `${this.getRatingsBaseUrl(
          '2018'
        )}/getCtvFacets?region=${selectedRegion}`;
      }
      if (param && param.length > 0) {
        for (const p of param) {
          urlString += `&facet=${p}`;
        }
      }
      if (
        paramSearch &&
        paramSearchValue &&
        paramSearch.length > 0 &&
        paramSearchValue.length > 0
      ) {
        urlString += `&${paramSearch}=${paramSearchValue}`;
      }
      if (selectedDevice) {
        const allDevice =
          'device=roku&device=firetv&device=samsung&device=tvos';
        const selectedDeviceString =
          selectedDevice === 'smartphone-tablet'
            ? allDevice
            : `device=${selectedDevice}`;
        if (selectedDevice && selectedDevice.length > 0) {
          urlString = urlString.concat(`&${selectedDeviceString}`);
        }
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }
      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.facetsDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  searchForApps(start: number, limit: number, filterString: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/searchCtvApps?start=${start}&limit=${limit}`;

      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (
        this.discoveryDataCache[urlString] &&
        urlString.indexOf('userId') < 0
      ) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  getRokuStats(filterString: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/ctvStats?nocache=${Math.random()}`;
      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.discoveryDataCache[urlString]) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  downloadCsv(filterString: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/getExportUri?reportId=ctvSearch&${filterString}&isAsync=true`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  checkImportQuota() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/accountBalance`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  importCSV(removeDuplicates = false, formData: FormData) {
    const headers = new Headers();
    headers.append('Content-type', 'application/octet-stream');

    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/importCtvApps?removeDuplicates=${removeDuplicates}`;
      return this.http.post(urlString, formData).subscribe(
        res => {
          resolve(res);
        },
        err => {
          resolve(err);
        }
      );
    });
  }

  getImportList() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl('2018')}/listCtvImport`;
      this.http.get(urlString).subscribe(res => {
        this.storeCache(res, this.discoveryDataCache, urlString);
        resolve(res);
      });
    });
  }

  getReportData(device = 'roku') {
    const urlString = `${this.getRatingsBaseUrl(
      '2018'
    )}/loadReport?reportId=ctvSummary&device=${device}`;
    return new Promise(resolve => {
      if (!this.reportDataCache[device]) {
        return this.http.get(urlString).subscribe(response => {
          this.reportDataCache[device] = response;
          resolve(response);
        });
      } else {
        resolve(this.reportDataCache[device]);
      }
    });
  }
}
